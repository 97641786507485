@value unit: var(--ring-unit);

.heading.heading {
  margin: 0;
}

.title.error {
  color: var(--ring-error-color);
}

.title.success {
  color: var(--ring-success-color);
}

.star {
  height: calc(unit * 3);
  margin-left: -2px;

  vertical-align: 3px;
}

.top {
  display: flex;
}

.topSequenceLoader {
  display: flex;
  flex-direction: row-reverse;

  margin-left: auto;

  gap: calc(unit / 2);
}

.subHeader {
  display: flex;

  align-items: baseline;

  width: 100%;
}

.description {
  flex: 1 0 auto;
}

.statusIcon {
  margin-right: calc(unit / 2);
}

.queuedIcon {
  color: var(--ring-text-color);
}

.personalQueuedIcon {
  & :global(.secondary.secondary) {
    fill: var(--ring-text-color);
  }
}

.branch {
  box-sizing: border-box;
  max-width: 40%;
  height: 24px;

  line-height: 24px;
}

.investigation {
  overflow: hidden;
}

.actions,
.branch,
.investigation {
  margin-right: calc(unit * 1.5);

  font-size: var(--ring-font-size-smaller);
  line-height: 24px;
}

.headerInfo {
  margin-bottom: unit;

  padding-right: calc(unit * 2);
}

.statusChangeComment {
  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.main {
  display: flex;
}

.leftColumn {
  overflow: hidden;
  flex: 1;

  margin-bottom: calc(unit * 3);

  margin-left: calc(var(--tc-page-padding-inline) * -1);

  padding: 0 calc(unit * 2) 0 var(--tc-page-padding-inline);
}

.rightColumn {
  margin-left: auto;

  padding-top: calc(unit * 1.5);
  padding-left: calc(unit * 2);

  line-height: 0;
}

.queued {
  font-weight: normal;
}

@value unit: var(--ring-unit);

.extensionsLinks {
  margin-top: unit;

  & a,
  & span {
    margin: 0;
  }

  & a {
    margin-left: unit;

    &:hover {
      color: var(--ring-link-hover-color);
    }
  }
}
